.table {
  color: #323436 !important;
}
.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #edf2f9;
}
.column-textarea {
  min-width: 270px;
}
.filter-range {
  width: 85% !important;
}
.flash {
  animation: flashAnimation 2s ease-in-out 1 alternate backwards;
}

.selectmulti__indicator {
  padding: 0px !important;
}
@keyframes flashAnimation {
  0% {
    background-color: inherit;
  }

  10%,
  90% {
    background-color: #b8d2f7;
  }

  100% {
    background-color: inherit;
  }
}
